export default {
    TEMPLATE_DESIGNER: {
        SAVE: 'product/template/save',
        ARCHIVE: 'product/template/archive',
        FETCH_ALL: 'product/template/list'
    },
    BOOKS: {
        PRINT_BOOKS: 'book/printBooks',
        FETCH_PREPRINTED_BOOKS: 'book/preprintedBooks',
        REGISTER_BOOK: 'scheduler/register',
        FETCH_BOOKS_FOR_SCHEDULE: 'book/fetchBooksForSchedule',
        FETCH_BOOK_BY_ID: 'book',
        FETCH_BOOKS_BY_ID: 'book/ids',
        REPRINT_BOOK: 'book/reprintBook',
        FETCH_DOWNLOADABLE_BOOKS: 'book/downloadableBooks',
        DOWNLOAD_BOOKS: 'book/downloadBooks?printRequestAuditId=',
        PRINT_ON_DEMAND_BOOKS: 'book/printOnDemand?printRequestAuditId='
    },
    SCHEDULER: {
        SAVE: 'scheduler/save',
        FETCH_ALL: 'scheduler/list',
        FETCH_ALL_DATES: 'scheduler/listScheduleDates',
        FETCH_ALL_FOR_DATE: 'scheduler/findByDate',
        FETCH_UPCOMING: 'scheduler/listUpcomingSchedules',
        START_SCHEDULE: 'scheduler/start',
        CANCEL_SCHEDULE: 'scheduler/cancel',
        END_SCHEDULE: 'scheduler/end',
        NEXT_GAME: 'scheduler/nextGame',
        FETCH_STATUS: 'scheduler/scheduleStatus',
        SET_BROADCAST: 'scheduler/setBroadcast'
    },
    PRODUCTS: {
        FETCH_ALL_FOR_SCHEDULE_ID: 'product/findAllBySchedule',
        SAVE_DETAILS: 'product/saveProductDetails',
        POS_PURCHASE: 'product/posPurchase'
    },
    CALLER: {
        CALL_NUMBER: 'caller/callNumber',
        RECALL_NUMBER: 'caller/recallNumber',
        NEXT_PATTERN: 'caller/nextPattern',
        VERIFY_WINNER: 'caller/verifyWinner',
        MISSED_WINNER: 'caller/missedWinner',
        UPDATE_ADD_MESSAGE: 'caller/updateAdvert'
    },
    PANEL: {
        FETCH_BY_ID: 'panel/'
    },
    USERS: {
        FETCH_ALL: 'user/list',
        SAVE: 'user/save',
        UPDATE: 'user/update',
        DELETE: 'user/delete',
        SEARCH_BY_USERNAME: 'user/search/byUsername'
    },
    TENANTS: {
        FETCH_ALL: 'tenant/list',
        SAVE: 'tenant/save',
    },
    AUTH: {
        LOGIN: 'login',
        PARTICIPATION: 'participation',
        FORGOT_PASSWORD: 'forgot-password',
        CHANGE_PASSWORD: 'user/changePassword',
        RESET_PASSWORD: 'reset-password',
        REFRESH_TOKEN: 'auth/refreshToken',
        SIGN_UP: 'user/signUp',
        UPDATE_DISPLAYNAME: 'user/update/displayName'
    },
    SYSTEM_ADMIN: {
        FETCH_COLOURS: 'config/listColours',
        SAVE_COLOURS: 'config/saveColours'
    },
    CUSTOMERS: {
        FETCH_ALL: 'customer/list',
        REGISTER: 'player/signup',
        UPDATE: 'player/update',
        DELETE: 'player/delete',
        RESET_PASSWORD: 'player/resetPassword'
    },
    REPORTING: {
        FETCH_GAME_SALES: 'reporting/gameSales',
        FETCH_GAME_BREAKDOWN: 'reporting/gameBreakdown',
        FETCH_BOOK_BREAKDOWN: 'reporting/booksBreakdown',
        FETCH_SCHEDULE_INFO: 'reporting/scheduleInfo'
    },
    TEAM: {
        CREATE: 'team/create',
        LIST: 'team/list',
        LIST_BY_COMPETITION: 'team/list/competition',
        FETCH_BY_ID: 'team/id'
    },
    COMPETITION: {
        CREATE: 'competition/create',
        UPDATE: 'competition/update',
        LIST: 'competition/list',
        PREDICTIONS_OPEN: 'competition/predictionsOpen',
        STATUS: 'competition/status',
        STANDINGS: 'competition/standings'
    },
    CLUB: {
        LIST: 'club/list',
        SEARCH: 'club/search',
        SEARCH_BY_COUNTY: 'club/search/county',
        UPDATE_USERS_CLUB: 'user/update/club'
    },
    FIXTURE: {
        CREATE: 'fixture/create',
        LIST: 'fixture/list',
        LIST_FOR_TEAM: 'fixture/list/team',
        IN_PLAY: 'fixture/inPlay',
        CURRENT: 'fixture/current',
        UPDATE: 'fixture/update'
    },
    RANKINGS: {
        HURLING: 'elo/hurling/current',
        TEAM: 'elo/hurling/team'
    },
    RESULT: {
        HEAD_TO_HEAD: 'result/team/headToHead',
        OVERALL: 'result/team',
        LIST_TEAMS: 'result/team/list',
        FINALS: 'result/team/finals',
        ALL_FINALS: 'result/finals'
    },
    LEAGUE: {
        PERSONALLIST: 'league/personal/list',
        PARTICIPANTS: 'league/personal/participants',
        STATE: 'league/current',
        LEAVE: 'league/leave',
        GAMEWEEK: 'league/gameweek',
        SNAPSHOT: 'league/snapshot',
        GAMEWEEK_SNAPSHOT: 'league/snapshot/gameweek',
        CREATE_PRIVATE: 'league/create/private',
        REGISTER_USER: 'league/register/user',
        SEARCH_BY_LEAGUE_NAME: 'league/search',
        EXISTS_BY_LEAGUE_NAME: 'league/unique'
    },
    SELECTION: {
        CONFIRM: 'game/prediction'
    },
    GAME: {
        WINNING_MARGINS: 'game/margins',
        PREDICTIONS: 'game/predictions',
        PAST_PREDICTIONS: 'game/past/predictions',
        END_WEEK: 'game/endGameWeek',
        GAMEWEEK_RESULT: 'game/gameweekResult'
    },
    STATISTICS: {
        TEAM_TOTAL_SCORE: '/score/team/totals',
        PLAYER_TOTAL_SCORE: '/score/player/totals',
        TEAM_SCORERS: '/score/team'
    },
    WINNERS: {
        LIST: '/winner/competition/list',
        LIST_FOR_TEAM: '/winner/competition/team',
        LIST_BY_TEAM: '/winner/competition/teams'
    }
}