import Config from '@/api/config.js'

const state = {
    teamTotals: [],
    playerTotals: [],
    team: []
};

const getters = {
    getTeamTotalValues: state => state.teamTotals.map(it => it.total),
    getTeamsTotalNames: state => state.teamTotals.map(it => it.team),
    getTeamsTotalColours: state => state.teamTotals.map(it => it.colour),
    getTeamScores: state => state.team,
    getPlayerTotalValues: state => state.playerTotals.map(it => it.total),
    getPlayerTotalNames: state => state.playerTotals.map(it => it.player),
    getPlayerTotals: state => state.playerTotals
}

const mutations = {
    setTeamTotals(state, teamTotalsData){
        state.teamTotals = teamTotalsData;
    },
    setPlayerTotals(state, playerTotalsData){
        state.playerTotals = playerTotalsData;
    },
    setTeam(state, team){
        state.team = team;
    }
};

const actions = {
  async fetchTeamTotals({ commit }, year) {
    try {
      const response = await Config.fetchTeamTotals(year);
      commit('setTeamTotals', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  },
  async fetchPlayerTotals({ commit }, data) {
    try {
      const response = await Config.fetchPlayerTotals(data.team, data.year);
      commit('setPlayerTotals', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  },
  async fetchTeamScorers({ commit }, team) {
    try {
      const response = await Config.fetchTeamScorers(team);
      commit('setTeam', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
