<template>
  <div>
  <!-- <base-item-group 
    no-action 
    sub-group 
    :item="item" 
    :noPadding="true"
    class="" />
    <v-divider class="ml-6"/> -->
    <div
      v-if="item.subheader"
      :key="item.subheader"
      class="px-9"
    >
    <span style="display: ruby;">
      <v-subheader>
        {{ item.subheader }}
      </v-subheader>
        <v-btn
          v-if="item.options && loggedInUser"
          color="secondary"
          fab
          dark
          class="custom-fab"
          @click="expanded = !expanded"
        >
          <v-icon v-if="expanded">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </span>

      <v-divider />
        <div>
          <transition name="fade">
            <v-row v-if="expanded" class="settings_height">
              <v-col cols="5">
                <v-btn
                  color="secondary"
                  small
                  depressed
                  @click="goTo('join')"
                >
                  Join
                  <v-icon>mdi-playlist-plus</v-icon>
              </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  color="secondary"
                  small
                  depressed
                  @click="goTo('create')"
                >
                  Create
                  <v-icon>mdi-pencil-plus-outline</v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </transition>
      </div>
      <v-divider />
      <base-item v-for="(child, i) in item.children"
        class=""
        :key="`item-${i}`"
        :item="child"
        :text="false"
        :smallIcon="true"
        :noPadding="true"
        :disabled="item.disabled">
      </base-item>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ItemSubGroup",

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
  },
  data(){
    return {
      expanded: false
    }
  },
  methods: {
    goTo(child){
      this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/leagues/private/${child}`)
    }
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  }
};
</script>
<style lang="scss">
.v-list-group--sub-group {
  .v-list-group__header {
    padding-left: 20px !important;
  }
}

.custom-fab {
  min-height: 20px; /* Smaller height */
  min-width: 20px; /* Smaller width */
  height: 20px;
  width: 20px;
  max-width: 20px;
  max-height: 20px;
  padding: 4px; /* Adjust padding to reduce size */
}

.custom-fab .v-icon {
  font-size: 16px !important; /* Smaller icon size */
}

.settings_height {
    max-height: 200px;
    overflow: hidden;
    }

.fade-enter-active, .fade-leave-active {
    transition: max-height 0.5s  ease-in;
    }
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
max-height: 0;
}
</style>
