import Auth from '@/api/auth.js'
import router from '@/router'

const getAuthDefaultState = () => {
    return {
        token: null,
        requiresPasswordReset: false,
        email: null,
        id: null,
        refreshToken: null,
        roles: [],
        userName: null,
        errorMessage: null,
        userParticipation: null
    }
}
const state = getAuthDefaultState()

const getters = {
    getToken: state => {
        const storedToken = localStorage.getItem('user')
        if (state.token || storedToken) {
            return state.token
        } else {
           return null
        }
    },
    getRequiresPasswordReset: state => state.requiresPasswordReset,
    getRefreshToken: state => state.refreshToken,
    getErrorMessage: state => state.errorMessage,
    getUserParticipation: state => state.userParticipation
}

const actions = {
    async login ({ commit, dispatch }, user) {
        let response = await Auth.login(user);
        let userInfo = response.data;
        if(!typeof userInfo === 'string'){
            userInfo.rememberMe = user.rememberMe;
        }
        commit('updateUserInfo', userInfo );
        dispatch('emailLogin', userInfo);
    },
    async participation({commit} , data){
        let response = await Auth.participation(data);
        commit('updateUserParticipation', response.data );
        return response.data;
    },
    async signup({ dispatch }, userInfo) {
       let response = await Auth.signup(userInfo);
       dispatch('login', { email: userInfo.email, password: userInfo.password, rememberMe: userInfo.rememberMe });
       return response.data;
    },
    async forgotPassword ({ dispatch }, email) {
        let response = await Auth.forgotPassword(email);
    },
    async changePassword ({ commit, dispatch }, passwordDetails) {

    },
    async resetPassword ({ dispatch }, passwordDetails) {
        let response;
        try {
            response = await Auth.resetPassword(passwordDetails);
            if(response.status == 200){
                router.push('sign-in')
            }
        } catch (error) {
            state.errorMessage = "Token is invalid or expired"
        }
    },
    async updateUserDisplayname ({ commit }, updateDetails) {
        let response = await Auth.updateDisplayName(updateDetails);
        if(response.data == -1){
            commit('updateErrorMessage', "Display name taken");
        } else {
            commit('updateErrorMessage', null);
        }
    },
    logout ({ commit }) {

    },
}

const mutations = {
    updateLocalStorageToken (state, token) {
        state.token = token
    },
    updateRequiresPasswordReset (state, required) {
        state.requiresPasswordReset = required
    },
    updateUserInfo (state, data) {
        state.email = data.email
        state.id = data.id
        state.refreshToken = data.refreshToken
        state.roles = data.roles
        state.userName = data.userName
    },
    updateErrorMessage (state, data){
        state.errorMessage = data;
    },
    updateUserParticipation(state, data){
        state.userParticipation = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
