import Config from '@/api/config.js'
import router from '@/router'

const state = {
    leagues: [],
    personalLeagues: [],
    leagueState: [],
    leaguesSnapshot: [],
    gameweekSnapshot: {},
    leaguesSearch: [],
    validName: false
};

const getters = {
    getPersonalLeagues: state => state.personalLeagues,
    getLeagueState: state => state.leagueState,
    getLeaguesSnapshot: state => state.leaguesSnapshot,
    getGameweekSnapshot: state => state.gameweekSnapshot,
    getCurrentLeague: state => state.leagues[0],
    getSearchedLeagues: state => { return state.leaguesSearch.map(league => ({
      text: league.name,
      value: league.id
    })); },
    isValidName: state => state.validName
}

const mutations = {
    setPersonalLeagues(state, personalLeagueData){
        state.personalLeagues = personalLeagueData;
    },
    setLeagueState(state, data){
      state.leagueState = data;
    },
    setLeagueData(state, leagueData){
      state.leagues = leagueData.data;
    },
    setLeaguesSnapshot(state, snapshotData){
      state.leaguesSnapshot = snapshotData.leagues;
    },
    setGameweekState(state, snapshotData){
      state.gameweekSnapshot = snapshotData;
    },
    setLeaguesState(state, leagues){
      state.leaguesSearch = leagues;
    },
    setIsValidName(state, valid){
      state.validName = valid;
    }
};

const actions = {
  async createPrivateLeague({ dispatch }, privateLeagueData) {
    try {
      const response = await Config.createPrivateLeague(privateLeagueData);
      let leagueId = response.data;
      dispatch('fetchPersonalLeagues', { "userId": privateLeagueData.userId, "sport": privateLeagueData.sport });
      router.push(`/app/hurling/leagues/${leagueId}`);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async registerUser({ dispatch }, userRegistrationData) {
    try {
      const response = await Config.registerUserInLeague(userRegistrationData);
      dispatch('fetchPersonalLeagues', { "userId": userRegistrationData.userId, "sport": userRegistrationData.sport })
      // TOD-Next - go to this league
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async exitLeague({ }, data){
    try {
      const response = await Config.leaveLeague(data);
      return response.data;
    } catch (error){
      console.error('Error leaving league', error);
    }
  },
  async fetchPersonalLeagues({ commit }, query) {
    try {
      const response = await Config.fetchPersonalLeagues(query.userId, query.sport);
      commit('setPersonalLeagues', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchParticipantsForLeague({commit}, leagueId){
    try {
      const response = await Config.fetchParticipantsForLeague(leagueId);
      commit('setLeagueData', response);
    }catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchLeagueState({commit}, leagueId){
    try {
      const response = await Config.fetchLeagueState(leagueId);
      commit('setLeagueState', response.data);
    } catch (error) {
      
    }
  },
  async fetchLeagueStateForGameweek({commit}, { leagueId: leagueId, gameweek: gameweek }){
    try {
      const response = await Config.fetchLeagueStateForGameweek(leagueId, gameweek);
      commit('setLeagueState', response.data);
    } catch (error) {
      
    }
  },
  async fetchLeaguesSnapshot({commit}, userId){
    try {
      const response = await Config.fetchLeaguesSnapshot(userId);
      commit('setLeaguesSnapshot', response.data);
    } catch (error) {
      
    }
  },
  async fetchGameweekSnapshot({commit}, { userId: userId, gameweek: gameweek, sport: sport}){
    try{  
      const response = await Config.fetchGameweekSnapshot(userId, gameweek, sport);
      commit('setGameweekState', response.data);
    } catch (error) {
      
    }
  },
  clearLeagueData({ commit }){
    commit('setLeaguesSnapshot', []);
    commit('setGameweekState', {});
  },
  async searchByLeagueName({ commit }, searchString) {
    try {
      const response = await Config.searchByLeagueName(searchString);
      commit('setLeaguesState', response.data);
    } catch (error) {
      console.error('Error searching for users:', error);
    }
  },
  async existsByLeagueName({ commit }, name) {
    try {
      const response = await Config.existsByLeagueName(name);
      commit('setIsValidName', !!!response.data);
      return true;
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error searching for users:', error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
