import Config from '@/api/config.js'

const state = {
    rankings: [],
    teamRanking: null,
    overallWinnerYearFromFilter: null,
    overallWinnerYearToFilter: null,
    overallCompetitionFilter: 'All Ireland',
    overallTotalsTeamSelections: [],
    overallTotalsYearFromFilter: null,
    overallTotalsYearToFilter: null,
};

const getters = {
    getRankings: state => { return state.rankings },
    getTeamRanking: state => { return state.teamRanking }
}

const mutations = {
    setRankings(state, rankings){
        state.rankings = rankings;
    },
    setOverallWinnerYearFromFilter(state, value){
        state.overallWinnerYearFromFilter = value;
    },
    setOverallWinnerYearToFilter(state, value){
        state.overallWinnerYearToFilter = value;
    },
    setOverallCompetitionFilter(state, value){
        state.overallCompetitionFilter = value;
    },
    setOverallTotalsTeamSelections(state, value){
        state.overallTotalsTeamSelections = value;
    },
    setOverallTotalsYearFromFilter(state, value){
        state.overallTotalsYearFromFilter = value;
    },
    setOverallTotalsYearToFilter(state, value){
        state.overallTotalsYearToFilter = value;
    },
    setTeamRanking(state, value){
      state.teamRanking = value;
  },
};

const actions = {
  async fetchRankings({ commit }, sport) {
    try {
      const response = await Config.findRankingsBySport(sport); // Make API call
      commit('setRankings', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching rankings:', error);
    }
  },
  async fetchRankingForTeam({ commit }, teamId) {
    try {
      const response = await Config.findRankingsByTeam(teamId); // Make API call
      commit('setTeamRanking', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching rankings:', error);
    }
  },
  updateOverallWinnerYearFromFilter({commit}, value){
    commit('setOverallWinnerYearFromFilter', value)
  },
  updateOverallWinnerYearToFilter({commit}, value){
    commit('setOverallWinnerYearToFilter', value)
  },
  updateOverallCompetitionFilter({commit}, value){
    commit('setOverallCompetitionFilter', value)
  },
  updateOverallTeamSelections({commit}, value){
    commit('setOverallTotalsTeamSelections', value)
  },
  updateOverallTotalsYearFromFilter({commit}, value){
    commit('setOverallTotalsYearFromFilter', value)
  },
  updateOverallTotalsYearToFilter({commit}, value){
    commit('setOverallTotalsYearToFilter', value)
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
