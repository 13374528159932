import firebase from "firebase/app";
import "firebase/auth";

export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    loading: false,
    error: null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loggedInUserId: state => state.loggedInUser ? state.loggedInUser.userDetails.id : null,
    loading: state => state.loading,
    error: state => state.error
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      localStorage.clear();
      window.location.reload();
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
    updatePaymentStatus(state){
      if(state.loggedInUser.userDetails){
        state.loggedInUser.userDetails.paid = true;
      }
    }
  },
  actions: {
    emailLogin({ commit }, user) {
      // bug here for logged in user
      if(user && user.userDetails){
        commit("clearError");
        commit("setLoading", true);
        commit("setUser", user);
      } else {
        commit("setError", user);
      }
      //if(user.rememberMe){
        localStorage.setItem("userInfo", JSON.stringify(user));
      //}
    },
    signOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            localStorage.removeItem("userInfo");
            commit("setLogout");
          },
          _error => {}
        );
    },
    updateUserDisplayname({ commit }) {
      
    },
    updatePaymentStatus({ commit }){
      commit("updatePaymentStatus");
    }
  }
};
