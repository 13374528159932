import Config from '@/api/config.js'

const state = {
    results: [],
    overallResults: [],
    headToHeadHomeFilter: null,
    headToHeadLocationFilter: null,
    headToHeadStageFilter: null,
    headToHeadStadiumFilter: null,
    headToHeadYearFromFilter: null,
    headToHeadYearToFilter: null,
    headToHeadRoundFilter: null,
    overallHomeFilter: null,
    overallOppositionFilter: null,
    overallYearFromFilter: null,
    overallYearToFilter: null,
    overallStageFilter: null,
    overallRoundFilter: null,
    overallStadiumFilter: null,
    finals: [],
    finalsSport: null,
    allFinals: []
};

const getters = {
    getResults: state => state.results.sort((a,b) => b.year - a.year),
    getOverallResults: state => state.overallResults.sort((a,b) => b.year - a.year),
    getOverallOppositionFilter: state => state.overallOppositionFilter,
    getHeadToHeadRoundOptions: state => { 
      let allRounds = state.results.map(it => it.round);
      if (allRounds.length > 0) {
        return Array.from(new Set(allRounds));
      }
      return [];
    },
    getRoundOptions: state => { 
      let allRounds = state.overallResults.map(it => it.round);
      if (allRounds.length > 0) {
        allRounds.unshift('All');
        return Array.from(new Set(allRounds));
      }
      return [];
    },
    getResultTotals: state => {
        const counts = {
            draw: 0
          };
    
          state.results.forEach(result => {
            if(state.headToHeadHomeFilter && state.headToHeadHomeFilter.value){
                if(state.headToHeadHomeFilter.value == -1){
                  if(!(result.location != result.homeTeam.name && result.location != result.awayTeam.name)){
                    return
                  }
                } else {
                  if(result.location != state.headToHeadHomeFilter.label) {
                    return
                  }
                }
            }
            if(state.headToHeadYearFromFilter && state.headToHeadYearFromFilter != 'All'){
              if(result.year < state.headToHeadYearFromFilter) return;
            }
            if(state.headToHeadYearToFilter && state.headToToYearFromFilter != 'All'){
              if(result.year > state.headToHeadYearToFilter) return;
            }
            // Check the result of the fixture and update counts accordingly
            switch (result.result) {
              case 'HOME_WIN':
                const homeTeamId = result.homeTeam.id.toString(); // Convert to string to use as object key
                counts[homeTeamId] = (counts[homeTeamId] || 0) + 1; // Increment or initialize to 1
                break;
              case 'AWAY_WIN':
                const awayTeamId = result.awayTeam.id.toString(); // Convert to string to use as object key
                counts[awayTeamId] = (counts[awayTeamId] || 0) + 1; // Increment or initialize to 1
                break;
              case 'DRAW':
                counts.draw += 1; // Increment draw count
                break;
              default:
                // Handle unexpected result values if necessary
                break;
            }
            if(!counts[result.homeTeam.id]){
                counts[result.homeTeam.id] = 0;
            }
            if(!counts[result.awayTeam.id]){
                counts[result.awayTeam.id] = 0;
            }
          });
    
          return counts;
    },
    getFilteredResults: state => {
      let filteredResults = state.results;
      if(state.headToHeadHomeFilter && state.headToHeadHomeFilter.value){
        if(state.headToHeadHomeFilter.id == -1){
          filteredResults = state.results.filter(it=>it.homeTeam.id != state.headToHeadHomeFilter.id && it.awayTeam != state.headToHeadHomeFilter.id)
        } else {
          filteredResults = state.results.filter(it=>it.homeTeam.id == state.headToHeadHomeFilter.id)
        }
      }
      if(state.headToHeadYearFromFilter && state.headToHeadYearFromFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year >= state.headToHeadYearFromFilter);
      }
      if(state.headToHeadYearToFilter && state.headToHeadYearToFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year <= state.headToHeadYearToFilter);
      }
      return filteredResults;
    },
    getFilteredStadiums: state => {
      let stadiums = ['All'];
      let filteredStadiums = new Set(state.results.map(it => it.venue));
      if(state.headToHeadHomeFilter && state.headToHeadHomeFilter.value){
        if(state.headToHeadHomeFilter.value == -1){
          filteredStadiums = new Set(state.results.filter(it=>it.location != it.homeTeam.name && it.location != it.awayTeam.name).map(it => it.venue));
        } else {
          filteredStadiums = new Set(state.results.filter(it=>it.location == state.headToHeadHomeFilter.label).map(it => it.venue));
        }
      }
      stadiums.push(...filteredStadiums);
      return stadiums;
    },
    getResultHurlingTotals: state => {
      const counts = {
          draw: 0
        };
  
        state.results.forEach(result => {
          if(state.headToHeadHomeFilter.value){
              if(state.headToHeadHomeFilter.value == -1){
                if(!(result.location != result.homeTeam.name && result.location != result.awayTeam.name)){
                  return
                }
              } else {
                if(result.location != state.headToHeadHomeFilter.label) {
                  return
                }
              }
          }
          if(state.headToHeadYearFromFilter && state.headToHeadYearFromFilter != 'All'){
            if(result.year < state.headToHeadYearFromFilter) return;
          }
          if(state.headToHeadYearToFilter && state.headToToYearFromFilter != 'All'){
            if(result.year > state.headToHeadYearToFilter) return;
          }
          if(state.headToHeadStadiumFilter && state.headToHeadStadiumFilter != 'All'){
            if(result.venue != state.headToHeadStadiumFilter) return;
          }
          if(state.headToHeadStageFilter && state.headToHeadStageFilter != 'All'){
            if(result.stage != state.headToHeadStageFilter) return;
          }
          // Check the result of the fixture and update counts accordingly
          switch (result.result) {
            case 'HOME_WIN':
              const homeTeamId = result.homeTeam.id.toString(); // Convert to string to use as object key
              counts[homeTeamId] = (counts[homeTeamId] || 0) + 1; // Increment or initialize to 1
              break;
            case 'AWAY_WIN':
              const awayTeamId = result.awayTeam.id.toString(); // Convert to string to use as object key
              counts[awayTeamId] = (counts[awayTeamId] || 0) + 1; // Increment or initialize to 1
              break;
            case 'DRAW':
              counts.draw += 1; // Increment draw count
              break;
            default:
              // Handle unexpected result values if necessary
              break;
          }
          if(!counts[result.homeTeam.id]){
              counts[result.homeTeam.id] = 0;
          }
          if(!counts[result.awayTeam.id]){
              counts[result.awayTeam.id] = 0;
          }
        });
  
        return counts;
    },
    getFilteredHurlingResults: state => {
      let filteredResults = state.results;
      if(state.headToHeadHomeFilter && state.headToHeadHomeFilter.value){
        if(state.headToHeadHomeFilter.value == -1){
          filteredResults = state.results.filter(it=>it.location != it.homeTeam.name && it.location != it.awayTeam.name);
        } else {
          filteredResults = state.results.filter(it=>it.location == state.headToHeadHomeFilter.label)
        }
      }
      if(state.headToHeadYearFromFilter && state.headToHeadYearFromFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year >= state.headToHeadYearFromFilter);
      }
      if(state.headToHeadYearToFilter && state.headToHeadYearToFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year <= state.headToHeadYearToFilter);
      }
      if(state.headToHeadStadiumFilter && state.headToHeadStadiumFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.venue == state.headToHeadStadiumFilter);
      }
      if(state.headToHeadStageFilter && state.headToHeadStageFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.stage == state.headToHeadStageFilter);
      }
      if(state.headToHeadRoundFilter && state.headToHeadRoundFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.round == state.headToHeadRoundFilter);
      }
      return filteredResults;
    },
    getOverallFilteredStadiums: state => {
      let stadiums = ['All'];
      let filteredStadiums = new Set(state.overallResults.map(it => it.venue));
      if(state.overallHomeFilter && state.overallHomeFilter.value){
        if(state.overallHomeFilter.value == -1){
          filteredStadiums = new Set(state.overallResults.filter(it=>it.location != it.homeTeam.name && it.location != it.awayTeam.name).map(it => it.venue));
        } else {
          if(state.overallHomeFilter.label.indexOf("home")> -1){
            filteredStadiums =new Set(state.overallResults.filter(it => it.location == state.overallHomeFilter.label.substring(0, state.overallHomeFilter.label.lastIndexOf(" "))).map(it => it.venue))
          } else {
            filteredStadiums =new Set(state.overallResults.filter(it => it.location != state.overallHomeFilter.label.substring(0, state.overallHomeFilter.label.lastIndexOf(" "))).map(it => it.venue))
          }
        }
      }
      stadiums.push(...filteredStadiums);
      return stadiums;
    },
    getOverallFilteredStages: state => {
      let stages = ['All', 'Munster', 'Leinster', 'Ulster', 'All Ireland'];
      return stages;
    },
    getFilteredOverallHurlingResults: state => {
      let filteredResults = state.overallResults;
      if(state.overallOppositionFilter == null) return [];
      if(state.overallOppositionFilter.length>0){
        filteredResults = [];
        let oppositionMatches = [];
        for(let i=0;i<state.overallOppositionFilter.length;i++){
          let oppositionTeam = state.overallOppositionFilter[i];
          oppositionMatches = state.overallResults.filter(it=>it.homeTeam.id == oppositionTeam.id || it.awayTeam.id == oppositionTeam.id);
          filteredResults.push(...oppositionMatches);
        }
      }
      if(state.overallHomeFilter && state.overallHomeFilter.value){
        if(state.overallHomeFilter && state.overallHomeFilter.value != -1 ){
          if(state.overallHomeFilter.label.indexOf("home") > -1){
            filteredResults = filteredResults.filter(result => result.location == state.overallHomeFilter.label.substr(0, state.overallHomeFilter.label.lastIndexOf(" ")));
          } else {
            filteredResults = filteredResults.filter(result => result.location != state.overallHomeFilter.label.substr(0, state.overallHomeFilter.label.lastIndexOf(" ")) 
              && (result.location == result.homeTeam.name || result.location == result.awayTeam.name))
          }
        } else if (state.overallHomeFilter.value == -1){
            filteredResults = filteredResults.filter(result => result.awayTeam.name != result.location && result.homeTeam.name != result.location);
        }
      }
      if(state.overallYearFromFilter && state.overallYearFromFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year >= state.overallYearFromFilter);
      }
      if(state.overallYearToFilter && state.overallYearToFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year <= state.overallYearToFilter);
      }
      if(state.overallStadiumFilter && state.overallStadiumFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.venue == state.overallStadiumFilter);
      }
      if(state.overallStageFilter && state.overallStageFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.stage == state.overallStageFilter);
      }
      if(state.overallRoundFilter && state.overallRoundFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.round == state.overallRoundFilter);
      }
      filteredResults.sort((a, b) => {
        // Compare years
        if (b.date[0] !== a.date[0]) {
          return b.date[0] - a.date[0];
        }
        // If years are equal, compare months
        if (b.date[1] !== a.date[1]) {
          return b.date[1] - a.date[1];
        }
        // If months are equal, compare days
        return b.date[2] - a.date[2];
      });
      filteredResults = filteredResults.reduce((accumulator, current) => {
        // Check if the current item's id is already in the accumulator
        if (!accumulator.some(item => item.id === current.id)) {
          accumulator.push(current); // Add to the accumulator if it's not already there
        }
        return accumulator;
      }, []);
      return filteredResults;
    },
    getFilteredOverallResults: state => {
      let filteredResults = state.overallResults;
      if(state.overallOppositionFilter.length>0){
        filteredResults = [];
        let oppositionMatches = [];
        for(let i=0;i<state.overallOppositionFilter.length;i++){
          let oppositionTeam = state.overallOppositionFilter[i];
          oppositionMatches = state.overallResults.filter(it=>it.homeTeam.id == oppositionTeam.id || it.awayTeam.id == oppositionTeam.id);
          filteredResults.push(...oppositionMatches);
        }
        filteredResults.sort((a,b)=>b.year-a.year);
      }
      if(state.overallHomeFilter){
        if(state.overallHomeFilter > 0){ //team at home selected
          filteredResults = filteredResults.filter(result => result.homeTeam.id == state.overallHomeFilter);
        } else { // team away selected
          filteredResults = filteredResults.filter(result => result.awayTeam.id == Math.abs(state.overallHomeFilter));
        }
      }
      if(state.overallFromFilter && state.overallFromFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year >= state.overallFromFilter);
      }
      if(state.overallToFilter && state.overallToFilter != 'All'){
        filteredResults = filteredResults.filter(result => result.year <= state.overallToFilter);
      }
      filteredResults = filteredResults.reduce((accumulator, current) => {
        // Check if the current item's id is already in the accumulator
        if (!accumulator.some(item => item.id === current.id)) {
          accumulator.push(current); // Add to the accumulator if it's not already there
        }
        return accumulator;
      }, []);
      return filteredResults;
    },
    getHeadToHeadHomeFilter: state => state.headToHeadHomeFilter,
    getFinals: state => {
      return [...state.finals].sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    getAllFinals: state => {
      return state.allFinals.map((match) => {
        // Determine winning and losing teams based on result
        let winningTeam, losingTeam, score;
        if (match.result === 'HOME_WIN') {
          winningTeam = match.homeTeam;
          losingTeam = match.awayTeam;
          score = `${match.homeGoals}-${match.homePoints}  ${match.awayGoals}-${match.awayPoints}`
        } else if (match.result === 'AWAY_WIN') {
          winningTeam = match.awayTeam;
          losingTeam = match.homeTeam;
          score = `${match.awayGoals}-${match.awayPoints}  ${match.homeGoals}-${match.homePoints}`
        } else if (match.result === 'DRAW') {
          winningTeam = null;
          losingTeam = null;
          score = `${match.homeGoals}-${match.homePoints}  ${match.awayGoals}-${match.awayPoints}`
        }
  
        // Return a new object with the original properties plus winningTeam and losingTeam
        return {
          ...match,
          winningTeam,
          losingTeam,
          score
        };
      }).sort((a, b) => {
        // Convert date arrays to Date objects for easy comparison
        const dateA = new Date(a.date[0], a.date[1] - 1, a.date[2]); // year, month (0-based), day
        const dateB = new Date(b.date[0], b.date[1] - 1, b.date[2]);

        // Sort by date descending
        return dateB - dateA;
      });;
    }
}

const mutations = {
    setResults(state, results){
      state.results = results;
    },
    setOverallResults(state, results){
      state.overallResults = results;
    },
    setHeadToHeadHomeFilter(state, homeTeam){
        state.headToHeadHomeFilter = homeTeam;
    },
    setHeadToHeadLocationFilter(state, location){
      state.headToHeadLocationFilter = location;
    },
    setHeadToHeadStageFilter(state, stage){
      state.headToHeadStageFilter = stage;
    },
    setHeadToHeadStadiumFilter(state, stadium){
      state.headToHeadStadiumFilter = stadium;
    },
    setHeadToHeadYearFromFilter(state, value){
      state.headToHeadYearFromFilter = value;
    },
    setHeadToHeadYearToFilter(state, value){
      state.headToHeadYearToFilter = value;
    },
    setHeadToHeadRoundFilter(state, round){
      state.headToHeadRoundFilter = round;
    },
    setOverallHomeFilter(state, value){
      state.overallHomeFilter = value;
    },
    setOverallYearFromFilter(state, value){
      state.overallYearFromFilter = value;
    },
    setOverallYearToFilter(state, value){
      state.overallYearToFilter = value;
    },
    setOverallOppositionFilter(state, value){
      state.overallOppositionFilter = value;
    },
    setOverallStageFilter(state, stage){
      state.overallStageFilter = stage;
    },
    setOverallRoundFilter(state, round){
      state.overallRoundFilter = round;
    },
    setOverallStadiumFilter(state, stadium){
      state.overallStadiumFilter = stadium;
    },
    setFinalsForTeam(state, finals){
      state.finals = finals;
    },
    setFinalsForSport(state, finals){
      state.allFinals = finals;
    },
    setFinalsSport(state, sport){
      state.finalsSport = sport;
    }
};

const actions = {
  async fetchHeadToHeadResults({ commit }, resultQuery) {
    try {
      const response = await Config.fetchResultsByTeamsAndCompetitionId(resultQuery.team1Id, resultQuery.team2Id, resultQuery.competitionId);
      commit('setResults', response.data);
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchOverallResults({ commit }, resultQuery) {
    try {
      const response = await Config.fetchResultsByTeamAndCompetitionId(resultQuery.teamId, resultQuery.competitionId);
      commit('setOverallResults', response.data);
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchFinalsForTeam({ commit }, teamId) {
    try {
      const response = await Config.fetchFinalsForTeam(teamId);
      commit('setFinalsForTeam', response.data);
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchFinalsForSport({ commit }, sport) {
    if(state.finalsSport == sport && state.allFinals.length > 0){
      return;
    }
    try {
      const response = await Config.fetchFinalsForSport(sport);
      commit('setFinalsForSport', response.data);
      commit('setFinalsSport', sport);
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  resetResultFilterOptions({ commit }){
    commit('setHeadToHeadHomeFilter', null);
    commit('setHeadToHeadLocationFilter', null);
    commit('setHeadToHeadStageFilter', null);
    commit('setHeadToHeadStadiumFilter', null);
    commit('setHeadToHeadYearFromFilter', null);
    commit('setHeadToHeadYearToFilter', null);
    commit('setHeadToHeadRoundFilter', null);
    commit('setOverallHomeFilter', null);
    commit('setOverallYearFromFilter', null);
    commit('setOverallYearToFilter', null);
    commit('setOverallOppositionFilter', null);
    commit('setOverallStageFilter', null);
    commit('setOverallRoundFilter', null);
    commit('setOverallStadiumFilter', null);
  },
  updateHeadToHeadHomeFilter({commit}, value){
    commit('setHeadToHeadHomeFilter', value)
  },
  updateHeadToHeadLocationFilter({commit}, value){
    commit('setHeadToHeadLocationFilter', value)
  },
  updateHeadToHeadStageFilter({commit}, value){
    commit('setHeadToHeadStageFilter', value)
  },
  updateHeadToHeadStadiumFilter({commit}, value){
    commit('setHeadToHeadStadiumFilter', value)
  },
  updateHeadToHeadYearFromFilter({commit}, value){
    commit('setHeadToHeadYearFromFilter', value)
  },
  updateHeadToHeadYearToFilter({commit}, value){
    commit('setHeadToHeadYearToFilter', value)
  },
  updateHeadToHeadRoundFilter({commit}, value){
    commit('setHeadToHeadRoundFilter', value)
  },
  updateOveralldHomeFilter({commit}, value){
    commit('setOverallHomeFilter', value)
  },
  updateOverallYearFromFilter({commit}, value){
    commit('setOverallYearFromFilter', value)
  },
  updateOverallYearToFilter({commit}, value){
    commit('setOverallYearToFilter', value)
  },
  updateOverallOppositionFilter({commit}, value){
    commit('setOverallOppositionFilter', value);
  },
  updateOverallStageFilter({commit}, value){
    commit('setOverallStageFilter', value)
  },
  updateOverallRoundFilter({commit}, value){
    commit('setOverallRoundFilter', value)
  },
  updateOverallStadiumFilter({commit}, value){
    commit('setOverallStadiumFilter', value)
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};